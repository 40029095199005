import { useEffect, useRef } from 'react';
import { formatISO, differenceInMinutes } from 'date-fns';
import { useNavigate } from 'react-router-dom';

function SessionTimeout() {
  let timeStamp;
  let warningInactiveInterval = useRef();
  let startTimerInterval = useRef();
  const navigate = useNavigate();

  const handleLogout = () => {
    if (localStorage.getItem('userSessionId') !== null) {
      localStorage.clear();
      navigate('/account/login');
      // window.location.reload();
      // console.log(localStorage.getItem("userSessionId"))
    }
  };

  // Start inactive check
  useEffect(() => {
    const timeChecker = () => {
      startTimerInterval.current = setTimeout(() => {
        warningInactive();
      }, 60000);
    };

    // Warning timer
    const warningInactive = () => {
      clearTimeout(startTimerInterval.current);
      const storedTimeStamp = localStorage.getItem('lastTimeStamp');
      warningInactiveInterval.current = setInterval(() => {
        const maxTime = 120; // Change maxTime to 120 minutes

        const diffMinutes = differenceInMinutes(
          new Date(),
          new Date(storedTimeStamp)
        );

        if (diffMinutes >= maxTime) {
          clearInterval(warningInactiveInterval.current);
          handleLogout(); // Trigger logout when max inactivity time is reached
        }
      }, 1);
    };

    // Reset interval timer
    const resetTimer = () => {
      if (localStorage.getItem('stopRefresh') === null) {
        clearTimeout(startTimerInterval.current);
        clearInterval(warningInactiveInterval.current);

        if (localStorage.getItem('userSessionId')) {
          timeStamp = new Date();
          localStorage.setItem('lastTimeStamp', formatISO(timeStamp));
        } else {
          clearInterval(warningInactiveInterval.current);
          localStorage.removeItem('lastTimeStamp');
        }
        timeChecker();
      } else {
        // localStorage.removeItem('stopRefresh');
        warningInactive();
      }
    };

    // Event listeners

    const events = ['click', 'load', 'scroll'];
    const eventListener = () => {
      events.forEach((event) => {
        window.addEventListener(event, resetTimer);
      });

      // Adding beforeunload event listener
      window.addEventListener('beforeunload', (event) => {
        if (localStorage.getItem('userSessionId')) {
          let timeStop = new Date();
          localStorage.setItem('stopRefresh', formatISO(timeStop));
        }

        // Check if the event is triggered by a refresh action
        if (
          !event.currentTarget.performance.navigationType ||
          event.currentTarget.performance.navigationType === 1
        ) {
          // If it's a refresh action, do not log out
          return;
        }

        // For other types of navigation, proceed with logout
        handleLogout();
      });

      // Adding unload event listener
      window.addEventListener('unload', (event) => {
        // Check if the event is triggered by a refresh action
        if (
          !event.currentTarget.performance.navigationType ||
          event.currentTarget.performance.navigationType === 1
        ) {
          // If it's a refresh action, do not log out
          return;
        }

        // For other types of navigation, proceed with logout
        handleLogout();
      });
    };

    eventListener();
    timeChecker();

    // Clean up
    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
      // Removing beforeunload event listener
      window.removeEventListener('beforeunload', handleLogout);
      // Removing unload event listener
      window.removeEventListener('unload', handleLogout);
      clearTimeout(startTimerInterval.current);
      clearInterval(warningInactiveInterval.current);
    };
  }, []);

  return null; // Render nothing
}

export default SessionTimeout;
