import React, { useEffect, useRef, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { CgCloseR, CgAddR } from 'react-icons/cg';
import loading from '../../../multimedia/loading.gif';
import Spinner from 'react-bootstrap/Spinner';
import CustomVideoPlayer from './CustomVideoPlayer';
import { HiInformationCircle } from 'react-icons/hi2';
import ReactGA from 'react-ga';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaUpload } from 'react-icons/fa6';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import gears from '../../../multimedia/loadingBigStarter.gif';
import Timeline from './Timeline';
import { AutoSizer, List } from 'react-virtualized';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import useProjectIdFromUrl from '../../../utils/UseProjectIdFromUrl';

export default function TranscendTranscribe({
  setSelectedTranscendComponent,
  projectName,
  projectId,
  setProjectName,
  setProjectId,
  speakersList,
  setSpeakersList,
  videoUrl,
  languageId,
  setLanguageId,
  setVoicesArray,
}) {
  const navigate = useNavigate();

  const apiURL = process.env.REACT_APP_API_URL;
  const [nextClicked, setNextClicked] = useState(false);
  const [show, setShow] = useState(false);
  const [transcriptStatus, setTranscriptStatus] = useState('display');
  const [reqIndex, setReqIndex] = useState(0);
  const [reqIdToRemove, setReqIdtoRemove] = useState('');
  const [removeRegionCount, setRemoveRegionCount] = useState(0);
  const [temp, setTemp] = useState([]);
  const [data, setData] = useState([]);
  const [newTranscriptData, setNewTranscriptData] = useState({});
  const [seekTime, setSeekTime] = useState({ value: 0 });
  const [regionClicked, setRegionClicked] = useState('');
  const [currentTime, setCurrentTime] = useState(0);
  const [cIndex, setCIndex] = useState(0);
  const [highlightedIndex, setHighlightedIndex] = useState(null);
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(100);
  const [regionChange, setRegionChange] = useState(false);
  const [regionIndex, setRegionIndex] = useState(null);
  const [count, setCount] = useState(0);
  const [peaks, setPeaks] = useState([]);
  const [waveDuration, setWaveDuration] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [updatingIndex, setUpdatingIndex] = useState(null);
  const [saved, setSaved] = useState(false);
  const [savedIndex, setSavedIndex] = useState(null);
  const [scrollIndex, setScrollIndex] = useState(0);
  const [file, setFile] = useState(null);
  const [currentStatus, setCurrentStatus] = useState(null);
  const fileInputRef = useRef(null);
  const [flag, setFlag] = useState(false);
  const [statusCount, setStatusCount] = useState(0);
  const [loopTranscript, setLoopTranscript] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [regionIdToUpdate, setRegionIdToUpdate] = useState('');
  const [tempData, setTempData] = useState([]);
  const [newlyAddedTranscript, setNewlyAddedTranscript] = useState();
  const [regionRemoveState, setRegionRemoveState] = useState(null);

  useProjectIdFromUrl(projectId, setProjectId);

  const dataRef = useRef(data);

  const handleWarningClose = () => {
    setShowWarning(false);
    setFlag(true);
  };
  const handleWarningShow = () => {
    setShowWarning(true);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const [showImport, setShowImport] = useState(false);
  const handleImportClose = () => {
    setShowImport(false);
    handleDeleteFile();
  };
  const handleImportShow = () => {
    ReactGA.event({
      category: 'Modal',
      action: 'Open',
      label: 'transcribe/import_transcripts',
    });

    setShowImport(true);
  };

  const notify = (error) =>
    toast.error(error, {
      className: 'custom-error-toast',
    });

  // google analytics

  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview('/spectral-transcend/transcribe');
    ReactGA.set({ page: '/spectral-transcend/transcribe' });
  }, []);

  function handleFileChange(selectedFile) {
    // console.log(selectedFile.name);
    setFile(selectedFile);
  }

  function handleDrop(e) {
    e.preventDefault();
    const selectedFile = e.dataTransfer.files[0];
    // console.log(selectedFile.name);
    setFile(selectedFile);
  }

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const InformationCircleTooltip = () => (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip style={{ fontSize: '12px' }}>
          Click here to download the reference file format
        </Tooltip>
      }
    >
      <Button
        variant="text"
        style={{ color: 'gray' }}
        onClick={(e) => handleInfoClick()}
      >
        <HiInformationCircle />
      </Button>
    </OverlayTrigger>
  );

  function handleInfoClick() {
    // Make the Fetch POST call
    fetch(`${apiURL}/web/get_reference_translation_file`, {
      method: 'POST',
      // No request body for a POST call according to your description
    })
      .then((response) => {
        // Check if the response is successful
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        ReactGA.event({
          category: 'Download',
          action: 'Download',
          label: 'transcribe/download_transcripts',
        });
        return response.blob(); // Extract the blob from the response
      })
      .then((blob) => {
        // Create a URL for the blob object
        const url = window.URL.createObjectURL(new Blob([blob]));
        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'reference_translation.xlsx'); // Set the filename
        // Append the link to the body
        document.body.appendChild(link);
        // Trigger the download
        link.click();
        // Clean up
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }

  const updateExportTranscripts = async () => {
    const reqBody = {
      ProjectId: projectId,
      TranscriptionList: data,
    };

    try {
      const response = await fetch(`${apiURL}/web/update_transcript`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const res = await response.json();

        if (res.IsValid) {
          exportTranscriptions();
        }
      } else {
        throw new Error('Failed to fetch project data');
      }
    } catch (error) {
      // console.error('Error:', error);
    }
  };

  function exportTranscriptions() {
    const reqBody = {
      ProjectId: projectId,
      Type: 1,
    };
    fetch(`${apiURL}/web/export_translations`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers if needed
      },
      body: JSON.stringify(reqBody),
    })
      .then((response) => {
        // Check if the response is successful
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.blob(); // Extract the blob from the response
      })
      .then((blob) => {
        // Create a URL for the blob object
        const url = window.URL.createObjectURL(new Blob([blob]));
        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${projectName}_transcriptions.xlsx`); // Set the filename
        // Append the link to the body
        document.body.appendChild(link);
        // Trigger the download
        link.click();
        // Clean up
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }

  // Form data and function for api call of import translations

  const formData = new FormData();
  formData.append('ProjectId', projectId);
  formData.append('file', file);

  function handleFileImport() {
    fetch(`${apiURL}/web/import_translations`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to fetch project data');
        }
      })
      .then((res) => {
        if (res.IsValid) {
          handleGetTranscripts(true);
          handleImportClose();
        } else {
          notify('Import unsuccessful, please upload a valid file!');
          console.error('Failed to upload transcription file');
        }
      })
      .catch((error) => {
        console.error('Error while uploading transcription file:', error);
      });
  }

  // function to perform get transcripts api call after importing transcripts

  const [newlyAddedId, setNewlyAddedId] = useState(null);

  function handleGetTranscripts(isImport = false) {
    const req = {
      ProjectId: projectId,
    };

    fetch(`${apiURL}/web/get_transcript`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to fetch project data');
        }
      })
      .then((newData) => {
        if (newData.IsValid) {
          // console.log(data);
          const differences = getDifferences(data, newData.ResultTranscripts);
          // console.log('Differences:', differences.added);
          const addedIds = getAddedIds(data, newData.ResultTranscripts);
          // console.log('Added Ids:', addedIds[0]);
          setNewlyAddedId(addedIds[0]);
          // setRegionClicked(addedIds[0])

          if (newData.Status === 6 && isImport) {
            setCurrentStatus(newData.Status);
            setStatusCount(statusCount + 1);
            setFlag(true);
          }
          setStatusCount(statusCount + 1);
          setData(newData.ResultTranscripts);
        }
      })
      .catch((error) => {
        // console.error('Error:', error);
      });
  }

  function getAddedIds(oldArray, newArray) {
    const oldMap = new Map(oldArray.map((item) => [item.Id, item]));
    const addedIds = newArray
      .filter((item) => !oldMap.has(item.Id))
      .map((item) => item.Id);

    return addedIds;
  }

  function getDifferences(oldArray, newArray) {
    const differences = {
      added: [],
      removed: [],
      updated: [],
    };

    const oldMap = new Map(oldArray.map((item) => [item.Id, item]));
    const newMap = new Map(newArray.map((item) => [item.Id, item]));

    newArray.forEach((item) => {
      if (!oldMap.has(item.Id)) {
        differences.added.push(item);
      } else if (JSON.stringify(oldMap.get(item.Id)) !== JSON.stringify(item)) {
        differences.updated.push(item);
      }
    });

    oldArray.forEach((item) => {
      if (!newMap.has(item.Id)) {
        differences.removed.push(item);
      }
    });

    return differences;
  }

  function handleGetTranscripts2() {
    const req = {
      ProjectId: projectId,
    };

    fetch(`${apiURL}/web/get_transcript`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to fetch project data');
        }
      })
      .then((newData) => {
        if (newData.IsValid) {
          if (newData.Status === 6) {
            setData(newData.ResultTranscripts);
          }
        }
      })
      .catch((error) => {
        // console.error('Error:', error);
      });
  }
  // function for api call to get peaks for waveforms

  function getPeaksforWaveform() {
    const req = {
      ProjectId: projectId,
    };
    fetch(`${apiURL}/dub/get_audio_waveform`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(req),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to fetch project data');
        }
      })
      .then((newData) => {
        if (newData.IsValid) {
          setPeaks(newData.Peaks);
          setWaveDuration(newData.Duration);
        }
      })
      .catch((error) => {
        // console.error('Error:', error);
      });
  }

  const handleDeleteFile = () => {
    setFile(null);
  };

  // useEffect(() => {
  //   // Update the highlighted transcript index when the currentTime changes
  //   const newIndex = data.findIndex(
  //     (transcript) =>
  //       currentTime >= transcript.StartTime && currentTime <= transcript.EndTime
  //   );
  //   setHighlightedIndex(newIndex);
  //   setScrollIndex(newIndex);
  // }, [currentTime, seekTime, data]);

  useEffect(() => {
    // Check if the data is already sorted
    const isSorted = data.every((item, index, array) => {
      // For the last item, return true
      if (index === 0) return true;
      // Ensure current item's StartTime is not less than the previous item's StartTime
      return item.StartTime >= array[index - 1].StartTime;
    });

    if (!isSorted) {
      const sortedData = sortDataByStartTime(data);
      setData(sortedData);
      handleLoop();
    }
  }, [data]);

  const sortDataByStartTime = (data) => {
    return data.slice().sort((a, b) => a.StartTime - b.StartTime);
  };

  useEffect(() => {
    // Updated the index of the transcript with the closest start time to the current time
    const newIndex = data.reduce((closestIndex, transcript, index) => {
      const closestTimeDiff = Math.abs(
        currentTime - data[closestIndex].StartTime
      );
      const currentTimeDiff = Math.abs(currentTime - transcript.StartTime);

      return currentTimeDiff < closestTimeDiff ? index : closestIndex;
    }, 0);

    console.log(data);
    const newIndex2 = data.findIndex((transcript) => {
      const startTime = parseFloat(transcript.StartTime.toFixed(2));
      const endTime = parseFloat(transcript.EndTime.toFixed(2));
      const current = parseFloat(currentTime.toFixed(2));

      return current >= startTime && current <= endTime;
    });

    // const newIndex4 = data.findIndex(
    //   (transcript) => currentTime < transcript.StartTime
    // );

    // Used when there are overlapping regions
    const newIndex3 = data.reduce((closestIndex, transcript, index) => {
      return currentTime >= transcript.StartTime ? index : closestIndex;
    }, 0);
    // console.log(newIndex2);
    // console.log(newIndex3);
    // console.log(newIndex4);

    if (newIndex2 !== -1) {
      // If currentTime is within a valid transcript range, set the highlighted index
      if (
        newIndex2 + 1 < data.length &&
        data[newIndex2].EndTime >= data[newIndex2 + 1].StartTime
      ) {
        setHighlightedIndex(newIndex3);
      } else if (
        currentTime >= data[newIndex2].StartTime &&
        currentTime < data[newIndex2].EndTime
      ) {
        setHighlightedIndex(newIndex2);
      } else {
        setHighlightedIndex(newIndex);
        // setRegionClicked(newIndex2);
      }
    } else {
      // If no transcript matches the time frame, reset the highlighted index
      setHighlightedIndex(null);
      setRegionClicked(null);
    }
    setRegionClicked(newIndex);
    setHighlightedIndex(newIndex2);

    // setSeekTime({ value: data[newIndex].StartTime });
    setScrollIndex(newIndex2);
  }, [currentTime, seekTime, data]);

  useEffect(() => {
    // code for looping transcript based on current time , index and loopTranscript boolean value
    if (loopTranscript && currentTime > data[cIndex].EndTime) {
      setCurrentTime(data[cIndex].StartTime);
      setSeekTime({ value: data[cIndex].StartTime });
    }
  }, [currentTime, loopTranscript, data]);

  // api call function for deleting transcript

  async function deleteTranscript(reqBody) {
    try {
      const response = await fetch(`${apiURL}/web//modify_single_transcript`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const res = await response.json();

        if (res.IsValid) {
          // console.log('working');
          setRegionRemoveState(reqIdToRemove);
          setRemoveRegionCount(count + 1);
          handleClose();
          // handleGetTranscripts();
        }
      } else {
        throw new Error('Failed to fetch project data');
      }
    } catch (error) {
      // console.error('Error:', error);
    }
  }

  // function to delete a transcript
  function handleDelete(index, regionId) {
    if (
      // index >= 0 && index < data.length
      regionId
    ) {
      const transcriptToDelete = data.find((item) => item.Id === regionId);
      // Create a copy of the data array without the element at the specified index
      // const updatedData = [...data.slice(0, index), ...data.slice(index + 1)];
      // console.log('transcript to be deleted is ', data[index]);
      const updatedData = data.filter((item) => item.Id !== regionId);
      // console.log('Transcript to be deleted is:', transcriptToDelete);

      setData(updatedData);

      // console.log(data);
      const reqBody = {
        ProjectId: projectId,
        Transcription: transcriptToDelete,
        Delete: true,
      };
      if (index == 0 && data.length == 1) {
        setSeekTime({ value: 0 });
      } else if (index == 0 && data.length > 1) {
        setSeekTime({ value: data[index + 1].StartTime });
      } else {
        setSeekTime({ value: data[index - 1].StartTime });
      }

      deleteTranscript(reqBody);
    } else {
      console.error('Invalid index provided for deleting transcript.');
    }
  }

  // api function call for adding new transcript

  async function addTranscriptApiCall(reqBody) {
    try {
      const response = await fetch(`${apiURL}/web//modify_single_transcript`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const res = await response.json();

        if (res.IsValid) {
          // handleGetTranscripts();

          const modifiedTranscript = {
            ...res.ModifiedTranscript,
            Id: res.ModifiedTranscript.Id.toUpperCase(),
            ProjectId: projectId,
            SourceLangId: null,
            SourceLanguageCode: null,
          };
          updateArrayWithNewObject(modifiedTranscript);

          // console.log(newlyAddedTranscript);
        }
      } else {
        throw new Error('Failed to fetch project data');
      }
    } catch (error) {
      // console.error('Error:', error);
    }
  }

  const updateArrayWithNewObject = (newObj) => {
    // Find the index where newObj should be inserted
    const index = data.findIndex((item) => item.StartTime > newObj.StartTime);

    // If index is -1, it means newObj should be added to the end
    const updatedData =
      index === -1
        ? [...data, newObj]
        : [
            ...data.slice(0, index),
            newObj, // Insert the new object
            ...data.slice(index),
          ];

    // Update the state with the newly created array
    setData(updatedData);
    setNewlyAddedTranscript(newObj);

    // setTempData(updatedData)
  };

  // function to add a new transcript

  function addTranscript(index) {
    let startTime, endTime;

    if (index === data.length - 1) {
      // If it's the last index, adjust startTime and set endTime
      startTime = data[index].EndTime + 0.1; // 1 second more than data[index].StartTime
      endTime = startTime + 1;
    } else if (data[index + 1].StartTime - data[index].EndTime > 1) {
      // For other indices, add 10 milliseconds to the start time and subtract 10 milliseconds from the end time
      startTime = data[index].EndTime + 0.1; // Add 10 milliseconds to the end time
      endTime = startTime + 1;
    } else if (
      data[index + 1].StartTime - data[index].EndTime <= 1 &&
      data[index + 1].StartTime - data[index].EndTime >= 0.1
    ) {
      startTime = data[index].EndTime + 0.1;
      endTime = data[index + 1].StartTime - 0.1; // Subtract 10 milliseconds from the start time
    } else if (data[index + 1].StartTime - data[index].EndTime < 0.1) {
      notify('Space insufficient for new entry');
      return;
    }
    const newTranscript = {
      StartTime: startTime,
      EndTime: endTime,
      //  Id:"tempId"+(index+1),
      SpeakerId: data[index].SpeakerId,
      SpeakerName: data[index].SpeakerName,
      Text: 'text',
      Duration: endTime - startTime,
    };
    if (index >= 0 && index < data.length) {
      const updatedTranscript = [
        ...data.slice(0, index + 1),
        newTranscript,
        ...data.slice(index + 1),
      ];
      setData([
        ...data.slice(0, index + 1),
        newTranscript,
        ...data.slice(index + 1),
      ]);

      const reqBody = {
        ProjectId: projectId,
        Transcription: newTranscript,
      };
      // setNewTranscriptData(newTranscript);
      addTranscriptApiCall(reqBody);
    } else {
      console.error('Invalid index provided for adding transcript.');
    }
  }

  const fetchSpeaker = async () => {
    const req = {
      ProjectId: projectId,
    };

    try {
      const response = await fetch(`${apiURL}/web/get_project_speaker`, {
        method: 'POST',

        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(req),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.IsValid) {
          setSpeakersList(data.SpeakerList);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // calls api as soon as page is rendered
  useEffect(() => {
    fetchSpeaker();

    //  function to call transcript api

    function fetchTranscripts() {
      const req = {
        ProjectId: projectId,
      };

      fetch(`${apiURL}/web/get_transcript`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(req),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Failed to fetch project data');
          }
        })
        .then((newData) => {
          if (newData.IsValid) {
            if (newData.Status === 6) {
              setCurrentStatus(newData.Status);
              getPeaksforWaveform();
              setStatusCount(statusCount + 1);
              clearInterval(intervalId);
              setFlag(true);
              setData(newData.ResultTranscripts);
              setTempData(newData.ResultTranscripts);
              // setAddData(newData.ResultTranscripts);
              // console.log(data);
            }
            setData([...(newData.ResultTranscripts || [])]);
            // setAddData([...(newData.ResultTranscripts || [])]);

            // console.log(data);
          }
        })
        .catch((error) => {
          // console.error('Error:', error);
        });
    }

    fetchTranscripts();
    const intervalId = setInterval(fetchTranscripts, 5000);

    return () => clearInterval(intervalId);
  }, []);

  // api call for updating transcriptions

  const updateTranscripts = async () => {
    const reqBody = {
      ProjectId: projectId,
      TranscriptionList: data,
    };

    try {
      const response = await fetch(`${apiURL}/web/update_transcript`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const res = await response.json();

        if (res.IsValid) {
          createTranslations();
          handleWarningClose();
        }
      } else {
        throw new Error('Failed to fetch project data');
      }
    } catch (error) {
      // console.error('Error:', error);
    }
  };

  // api call for creating translations

  const createTranslations = async () => {
    const reqBody = {
      ProjectId: projectId,
      TargetLanguageId: languageId,
    };

    setNextClicked(true);
    try {
      const response = await fetch(`${apiURL}/dub/translate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const res = await response.json();

        if (res.IsValid) {
          setSelectedTranscendComponent('translate');
          navigate(`/transcend/${projectId.toLowerCase()}/translate`);
        }
      } else {
        setNextClicked(false);
        throw new Error('Failed to fetch project data');
      }
    } catch (error) {
      setNextClicked(false);
      // console.error('Error:', error);
    }
  };

  function handleNext() {
    if (flag === true) {
      if (data.some((item) => item.Text === '')) {
        handleWarningShow();
      } else {
        updateTranscripts();
      }
    }
  }

  async function deleteAndProceed() {
    const filteredData = data.filter((item) => item.Text !== '');
    const reqBody = {
      ProjectId: projectId,
      TranscriptionList: filteredData,
    };

    try {
      const response = await fetch(`${apiURL}/web/update_transcript`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const res = await response.json();

        if (res.IsValid) {
          handleWarningClose();
          createTranslations();
        }
      } else {
        throw new Error('Failed to fetch project data');
      }
    } catch (error) {
      // console.error('Error:', error);
    }
  }

  // convert seconds to hh:mm:ss format

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    const milliseconds = Math.round((timeInSeconds % 1) * 100);

    const pad = (value, length) => {
      const strValue = value.toString();
      return strValue.length < length ? pad(`0${strValue}`, length) : strValue;
    };

    return `${pad(hours, 2)}:${pad(minutes, 2)}:${pad(seconds, 2)}.${pad(
      milliseconds,
      2
    )}`;
  };

  // convert hh:mm:ss to seconds format
  const convertTimeToSeconds = (timeString) => {
    const [hh, mm, ss] = timeString.split(':');
    const [seconds, milliseconds] = ss.split('.');

    const totalSeconds =
      parseInt(hh, 10) * 3600 +
      parseInt(mm, 10) * 60 +
      parseInt(seconds, 10) +
      parseFloat(`0.${milliseconds}`);

    return totalSeconds;
  };

  // function call to edit textarea
  const handleTextareaChange = (index, value, d) => {
    const initialText = data[index].Text;
    // setRegionIndex(index);
    setCount(count + 1);
    if (flag === false) {
      const temporary = [...data];
      temporary[index].Text = value;
      setTemp(temporary);
    }
    const newText = value;
    setData((prevData) =>
      prevData.map((item, i) =>
        i === index ? { ...item, Text: newText } : item
      )
    );
    const transcription = [d];

    const temp = transcription.map((item) => ({ ...item, Text: newText }));
    if (initialText !== newText) {
      updateSingleTranscription(temp, data[index].Id);
    }
  };
  const resetSaved = () => {
    setTimeout(() => {
      setSaved(false);
      setSavedIndex(null);
    }, 2000);
  };

  async function updateSingleTranscription(temp, id) {
    setUpdating(true);
    // console.log(regionIdToUpdate, updating);
    // setUpdatingIndex(id);
    setRegionIdToUpdate(id);
    const reqBody = {
      ProjectId: projectId,
      Transcription: temp[0],
    };

    try {
      const response = await fetch(`${apiURL}/web//modify_single_transcript`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(reqBody),
      });

      if (response.ok) {
        const res = await response.json();

        if (res.IsValid) {
          setUpdating(false);
          setNewTranscriptData(temp);
          const updateData = () => {
            setData((prevData) =>
              prevData.map((item) => (item.Id === temp[0].Id ? temp[0] : item))
            );
          };
          updateData();
          // console.log(temp);
          // setUpdatingIndex(null);
          setSaved(true);
          setSavedIndex(id);
          resetSaved();
        }
      } else {
        throw new Error('Failed to fetch project data');
      }
    } catch (error) {
      // console.error('Error:', error);
    }
  }

  // useEffect(() => {
  //   // Once the condition is met, update the main state with changes from the temporary state
  //   if (flag && temp.length > 0) {
  //     setData((prevData) =>
  //       prevData.map((item, index) =>
  //         temp[index] ? { ...item, ...temp[index] } : item
  //       )
  //     );
  //   }
  // }, [flag, temp]);

  // function call to handle looping of trancript
  function handleLoop() {
    setLoopTranscript(!loopTranscript);
    const currentIndex = data.findIndex((transcript, index) => {
      if (currentTime < data[0].StartTime) {
        return true;
      } else if (
        currentTime >= transcript.StartTime &&
        currentTime <= transcript.EndTime
      ) {
        return true;
      } else if (
        index < data.length - 1 &&
        currentTime > transcript.EndTime &&
        currentTime < data[index + 1].StartTime
      ) {
        return true;
      }
      return false;
    });
    setCIndex(currentIndex);
    setRegionClicked(currentIndex);
  }

  const renderRow = ({ index, key, style }) => (
    <div
      className={`subtitles_div_${transcriptStatus}`}
      key={data[index].Id}
      style={style}
    >
      <div
        className={`transcripts ${
          highlightedIndex === index ? 'highlighted' : ''
        }`}
      >
        <div className="speaker_select">
          <p style={{ fontWeight: 500 }}>Speaker</p>
          <Form.Select
            style={{
              height: '40px',
              fontSize: '14px',
              width: '150px',
              overflow: 'hidden',
            }}
            defaultValue={data[index].SpeakerName}
            onChange={(e) => {
              const initialSpeakerId = data[index].SpeakerId;
              const newSpeakerName = e.target.value;
              const newSpeakerId = speakersList.find(
                (speaker) => speaker.Name === newSpeakerName
              )?.Id;
              if (flag === false) {
                const temporaryDataCopy = [...data];
                temporaryDataCopy[index].SpeakerName = newSpeakerName;
                temporaryDataCopy[index].SpeakerId = newSpeakerId;
                setTemp(temporaryDataCopy);
              }
              setData((prevData) =>
                prevData.map((item, i) =>
                  i === index
                    ? {
                        ...item,
                        SpeakerName: newSpeakerName,
                        SpeakerId: newSpeakerId,
                      }
                    : item
                )
              );
              const arr = [data[index]];
              const temp = arr.map((item) => ({
                ...item,
                SpeakerName: newSpeakerName,
                SpeakerId: newSpeakerId,
              }));
              if (initialSpeakerId !== newSpeakerId) {
                updateSingleTranscription(temp, data[index].Id);
              }
            }}
          >
            {speakersList.map((speaker, i) => (
              <option key={i}>{speaker.Name}</option>
            ))}
          </Form.Select>
        </div>
        <div className="timers">
          <Form.Control
            className="timer_input"
            style={{ width: '120px', fontSize: '14px', height: '40px' }}
            type="text"
            key={data[index].StartTime}
            defaultValue={formatTime(data[index].StartTime)}
            onBlur={(e) => {
              setRegionIndex(index);
              setCount(count + 1);
              const initialStartTime = data[index].StartTime;
              const previousEndTime = index > 0 ? data[index - 1].EndTime : 0;
              const newStartTime = convertTimeToSeconds(e.target.value);
              const duration = data[index].EndTime - newStartTime;
              if (flag === false) {
                const temporaryDataCopy = [...data];
                temporaryDataCopy[index].StartTime = newStartTime;
                temporaryDataCopy[index].Duration = duration;
                setTemp(temporaryDataCopy);
              }
              setData((prevData) =>
                prevData.map((item, i) =>
                  i === index
                    ? { ...item, StartTime: newStartTime, Duration: duration }
                    : item
                )
              );
              const arr = [data[index]];
              const temp = arr.map((item) => ({
                ...item,
                StartTime: newStartTime,
                Duration: duration,
              }));
              if (initialStartTime !== newStartTime) {
                updateSingleTranscription(temp, data[index].Id);
              }
            }}
          />
          <Form.Control
            className="timer_input"
            style={{ width: '120px', fontSize: '14px', height: '40px' }}
            type="text"
            key={data[index].EndTime}
            defaultValue={formatTime(data[index].EndTime)}
            onBlur={(e) => {
              setRegionIndex(index);
              setCount(count + 1);
              const initialEndTime = data[index].EndTime;
              const newEndTime = convertTimeToSeconds(e.target.value);
              const nextStartTime =
                index < data.length - 1
                  ? data[index + 1].StartTime
                  : data[index - 1].EndTime + 0.1;
              const duration = newEndTime - data[index].StartTime;
              if (flag === false) {
                const temporaryDataCopy = [...data];
                temporaryDataCopy[index].EndTime = newEndTime;
                temporaryDataCopy[index].Duration = duration;
                setTemp(temporaryDataCopy);
              }
              setData((prevData) =>
                prevData.map((item, i) =>
                  i === index
                    ? { ...item, EndTime: newEndTime, Duration: duration }
                    : item
                )
              );
              const arr = [data[index]];
              const temp = arr.map((item) => ({
                ...item,
                EndTime: newEndTime,
                Duration: duration,
              }));
              if (initialEndTime !== newEndTime) {
                updateSingleTranscription(temp, data[index].Id);
              }
            }}
          />
        </div>
        <div
          className="textareaDiv"
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
            position: 'relative',
          }}
        >
          <Form.Control
            className="txtarea"
            as="textarea"
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderRight: 0,
              resize: 'none',
            }}
            rows={3}
            cols={50}
            defaultValue={data[index].Text}
            onClick={(e) => {
              setCIndex(index);
              setSeekTime({ value: data[index].StartTime });
              setRegionClicked(data[index].Id);
            }}
            onBlur={(e) => {
              handleTextareaChange(index, e.target.value, data[index]);
            }}
            data-gramm="false"
            data-gramm_editor="false"
            data-enable-grammarly="false"
          />
          <div className="edit-textBtns">
            <Button
              variant="text"
              style={{
                color: 'grey',
                fontSize: '20px',
                padding: 0,
                marginLeft: '5px',
              }}
              onClick={() => {
                if (data.length > 1) {
                  setReqIndex(index);
                  setReqIdtoRemove(data[index].Id);
                  handleShow();
                } else if (data.length == 1) {
                  notify('Atleast 1 transcription is required');
                }
              }}
            >
              <CgCloseR />
            </Button>
            <Button
              variant="text"
              style={{
                color: 'grey',
                fontSize: '22px',
                padding: 0,
                marginLeft: '5px',
                fontWeight: 500,
              }}
              onClick={() => {
                addTranscript(index);
              }}
            >
              <CgAddR />
            </Button>
          </div>
          {updating === true && data[index].Id === regionIdToUpdate ? (
            <div
              style={{
                position: 'absolute',
                bottom: '5px',
                right: '40px',
                color: 'gray',
                fontSize: '12px',
              }}
            >
              <span>Saving</span>
              <span className="ms-1 me-1">
                <Spinner
                  variant="secondary"
                  animation="border"
                  size="sm"
                  style={{ height: '12px', width: '12px' }}
                />
              </span>
            </div>
          ) : null}
          {saved === true && data[index].Id === regionIdToUpdate ? (
            <div
              style={{
                position: 'absolute',
                bottom: '5px',
                right: '40px',
                color: 'gray',
                fontSize: '12px',
              }}
            >
              <span>Saved</span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );

  async function change_project_result_status() {
    const req = {
      ProjectId: projectId,
      CurrentStatus: currentStatus,
      TargetStatus: 1,
    };
    try {
      const response = await fetch(
        `${apiURL}/web/change_project_result_status`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // 'ApiKey': apiKey,
          },
          body: JSON.stringify(req),
        }
      );
      const res = await response.json();

      if (res.IsValid) {
        setProjectId(projectId);
        setProjectName(projectName);
        setLanguageId(languageId);
        setVoicesArray(JSON.parse(localStorage.getItem('voiceList')));
        setSelectedTranscendComponent('speaker');
        navigate(`/transcend/${projectId.toLowerCase()}/speaker`);
      } else {
        notify('Import unsuccessful, please upload a valid file!');
      }
    } catch (error) {
      console.error(error);
      notify('Import unsuccessful, please upload a valid file!');
    }
  }

  function back() {
    // console.log('back clicked');
    change_project_result_status();
  }

  return (
    // <div className='syncPage'>
    <div style={{ padding: '1%' }}>
      <div className="transcendTranscribe" style={{ position: 'relative' }}>
        <span>
          {flag === true ? (
            <span style={{ position: 'absolute', left: 10, top: 10 }}>
              <Button
                style={{ fontSize: '20px', fontWeight: 500 }}
                variant="text"
                onClick={(e) => back()}
              >
                <IoArrowBack />
                <span className="ms-1" style={{ fontSize: '14px' }}>
                  Back
                </span>
              </Button>
            </span>
          ) : null}
        </span>
        <span>
          {' '}
          <h5 style={{ textAlign: 'center' }}>Edit Transcript</h5>
          <div
            className="mb-3"
            style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}
          >
            {flag === true ? (
              <Button
                variant="outline-dark"
                onClick={(e) => handleImportShow()}
              >
                Import
              </Button>
            ) : (
              <Button variant="outline-dark" disabled>
                Import
              </Button>
            )}
            {flag === true ? (
              <Button
                variant="outline-dark"
                onClick={(e) => updateExportTranscripts()}
              >
                Export
              </Button>
            ) : (
              <Button variant="outline-dark" disabled>
                Export
              </Button>
            )}
            <InformationCircleTooltip />
          </div>
          {flag === false && nextClicked === false && data.length > 0 ? (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <span
                style={{
                  marginRight: '10px',
                  fontSize: '14px',
                  fontWeight: 500,
                }}
              >
                Transcription is in progress
              </span>{' '}
              <Spinner size="sm" animation="border" variant="success" />
            </div>
          ) : null}
        </span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              width: '49.5%',
              maxWidth: '49.5%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <CustomVideoPlayer
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              videoRef={videoRef}
              videoUrl={videoUrl}
              currentTime={currentTime}
              setZoomLevel={setZoomLevel}
              setCurrentTime={setCurrentTime}
              seekTime={seekTime}
              handleLoop={handleLoop}
              loopTranscript={loopTranscript}
              isMuted={isMuted}
              setIsMuted={setIsMuted}
            />
          </div>
          <div className="captions">
            {/* Transcripts data is mapped here---------------------------------------------------------------> */}

            {data.length > 0 ? (
              <AutoSizer>
                {({ height, width }) => (
                  <List
                    width={width}
                    height={height}
                    rowRenderer={renderRow}
                    rowCount={data.length}
                    rowHeight={
                      window.innerWidth > 1919
                        ? 125
                        : window.innerWidth > 1600
                        ? 118
                        : 118
                    }
                    scrollToIndex={scrollIndex}
                  />
                )}
              </AutoSizer>
            ) : flag === false ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <img
                  style={{ height: '230px', width: '220px' }}
                  src={gears}
                  alt="In progress"
                />
                <p style={{ fontWeight: 500, marginTop: '20px' }}>
                  Please wait, transcription is in progress.
                </p>
              </div>
            ) : (
              <p style={{ color: 'red', textAlign: 'center' }}>
                No transcripts for the project
              </p>
            )}
          </div>
        </div>
        {flag === true ? (
          <Row style={{ marginTop: '1%' }}>
            <Timeline
              data={data}
              setData={setData}
              newlyAddedId={newlyAddedId}
              videoRef={videoRef}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              regionIndex={regionIndex}
              count={count}
              zoomLevel={zoomLevel}
              setZoomLevel={setZoomLevel}
              setSeekTime={setSeekTime}
              regionClicked={regionClicked}
              setRegionClicked={setRegionClicked}
              peaks={peaks}
              waveDuration={waveDuration}
              setScrollIndex={setScrollIndex}
              statusCount={statusCount}
              updateSingleTranscription={updateSingleTranscription}
              // updateSingleTranscription2={updateSingleTranscription2}
              setTempData={setTempData}
              tempData={tempData}
              setRegionChange={setRegionChange}
              reqIdToRemove={reqIdToRemove}
              removeRegionCount={removeRegionCount}
              updatingIndex={updatingIndex}
              newTranscriptData={newTranscriptData}
              newlyAddedTranscript={newlyAddedTranscript}
              dataRef={dataRef}
              regionIdToUpdate={regionIdToUpdate}
              setRegionIdToUpdate={setRegionIdToUpdate}
              regionRemoveState={regionRemoveState}
            />
          </Row>
        ) : (
          <Row style={{ marginTop: '1%' }}>
            <p style={{ textAlign: 'center' }}>
              Please wait, transcription is in progress.
            </p>
          </Row>
        )}
      </div>
      <div className="nxtBtnContainer">
        {flag === true ? (
          <button
            className="nxtBtn"
            onClick={() => {
              handleNext();
            }}
          >
            Next
          </button>
        ) : flag === true && nextClicked === true ? (
          <div>
            {' '}
            <button className="nxtBtn" disabled>
              Next
            </button>{' '}
            <span>
              <img
                style={{ width: '50px', height: '50px', marginLeft: '10px' }}
                src={loading}
                alt="loading"
              />
            </span>{' '}
          </div>
        ) : (
          <button className="nxtBtn" disabled>
            Next
          </button>
        )}
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Transcript</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={() => handleDelete(reqIndex, reqIdToRemove)}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showImport} onHide={handleImportClose}>
        <Modal.Header closeButton>
          <Modal.Title>Import transcriptions</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ textAlign: 'center', position: 'relative' }}
          onDrop={(e) => handleDrop(e)}
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {file !== null ? (
            <Button
              variant="text"
              className="delete-button"
              style={{
                position: 'absolute',
                top: 15,
                right: 15,
                fontWeight: 700,
              }}
              onClick={handleDeleteFile}
            >
              <RiDeleteBin6Line />
            </Button>
          ) : null}
          {file === null ? (
            <div>
              <Button
                variant="text"
                style={{ fontSize: '50px' }}
                onClick={handleUploadClick}
              >
                <FaUpload />
              </Button>
              <p style={{ fontWeight: 500 }}>
                Drop your excel file here, or click to browse
              </p>
              <input
                ref={fileInputRef}
                type="file"
                style={{ display: 'none' }}
                accept=".xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={(e) => handleFileChange(e.target.files[0])}
              />
            </div>
          ) : (
            <div>
              <p className="mb-2 mt-2">{file.name}</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleImportClose}>
            Cancel
          </Button>
          {file !== null ? (
            <Button variant="primary" onClick={(e) => handleFileImport()}>
              Upload
            </Button>
          ) : (
            <Button variant="secondary" disabled>
              Upload
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      {/* Modal to display warning for empty transcripts */}
      <Modal show={showWarning} onHide={handleWarningClose}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          There are empty transcriptions which may cause undesired effects in
          the results. What would you like to do?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleWarningClose}>
            Close
          </Button>
          <Button variant="primary" onClick={(e) => deleteAndProceed()}>
            Delete & proceed
          </Button>
          <Button variant="warning" onClick={(e) => updateTranscripts()}>
            Proceed anyway
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  );
}
