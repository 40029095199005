import React, { useState, useEffect, useRef, useReducer } from 'react';
import { FcGoogle } from 'react-icons/fc';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import './registration.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { formatISO } from 'date-fns';
import ReactGA from 'react-ga';

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'black',
    color: 'lightblue',
    '& fieldset': {
      borderColor: 'lightblue',
    },
    '&:hover fieldset': {
      borderColor: 'lightblue',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'lightblue',
    },
    '& .MuiInputBase-input': {
      backgroundColor: 'black !important',
    },
  },
  '& .MuiInputLabel-root': {
    color: 'lightblue',
  },
  '& .MuiSelect-root': {
    backgroundColor: 'black',
    color: 'lightblue',
    '&:focus': {
      backgroundColor: 'black',
    },
  },
  width: '90%',
  height: '40px',
  margin: '5%',
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  color: 'lightblue',
}));

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Work email address is required'),
  password: Yup.string()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      'Invalid password'
    )
    .required('Password is required'),
});

export default function Login({ setSelectedRegComponent, userId, setUserId }) {
  const [showPassword, setShowPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [msg, setMsg] = useState('');
  const [showVerifySegment, setShowVerifySegment] = useState(false);
  const [verificationCode, setVerificationCode] = useState([
    '',
    '',
    '',
    '',
    '',
    '',
  ]);
  const [verificationError, setVerificationError] = useState('');
  const apiURL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  let timeStamp;
  const inputRefs = useRef([]);

  // Google Analytics
  const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview('/account/login');
    ReactGA.set({ page: '/account/login' });
  }, []);

  useEffect(() => {
    document.title = 'Login - Spectral Studio';
  }, []);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values) => {
      if (isFormValid) {
        const reqBody = {
          Email: values.email,
          Password: values.password,
        };
        fetch(`${apiURL}/web/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(reqBody),
        })
          .then((response) => response.json())
          .then((data) => {
            if (
              data.IsValid &&
              data.IsVerified &&
              data.IsActive &&
              data.IsVerificationRequired === false &&
              !data.Error
            ) {
              setSessionId(data.UserId);
              fetchLanguageName();
              fetchUserInfo(data.UserId);
              setErrorMsg(false);
              setMsg('');
            } else if (
              data.IsValid &&
              data.IsVerified &&
              data.IsActive &&
              data.IsVerificationRequired === true &&
              !data.Error
            ) {
              console.log(data.UserId);
              try {
                localStorage.setItem('userSessionId', data.UserId);
                timeStamp = new Date();
                localStorage.setItem('lastTimeStamp', formatISO(timeStamp));
              } catch (err) {
                console.error('Error saving to localStorage:', err);
              }

              setUserId(data.UserId);

              fetchLanguageName();
              fetchUserInfo(data.UserId);
              setErrorMsg(false);
              setMsg('');

              setShowVerifySegment(true);
            } else {
              setErrorMsg(true);

              setMsg(data.Message || '* Invalid credentials, please try again');
            }
          })
          .catch((error) => {
            console.error('API call failed:', error);
          });
      }
    },
  });

  const isFormValid = formik.dirty && formik.isValid;

  const fetchLanguageName = () => {
    fetch(`${apiURL}/web/get_language_list`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.IsValid) {
          localStorage.setItem(
            'languagesList',
            JSON.stringify(data.LanguageList)
          );
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const setSessionId = (id) => {
    try {
      localStorage.setItem('userSessionId', id);
      const timeStamp = new Date();
      localStorage.setItem('lastTimeStamp', formatISO(timeStamp));
    } catch (err) {
      console.log('Error saving to localStorage:', err);
    }
  };

  const fetchUserInfo = (id) => {
    const reqBody = { UserId: id };
    fetch(`${apiURL}/web/get_user_info`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reqBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.IsValid) {
          localStorage.setItem('userInfo', JSON.stringify(data.UserInfo));
          if (JSON.parse(localStorage.getItem('userInfo')).Role === 'Studio') {
            // sendVerification();
            navigate('/');
          }
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  // const sendVerification = () => {
  //   const reqBody = { UserId: localStorage.getItem('userSessionId') };
  //   fetch(`${apiURL}/web/request_login_otp`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(reqBody),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.IsValid) {
  //         setShowVerifySegment(true);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error:', error);
  //     });
  // };

  useEffect(() => {
    // Focus on the first input element when the verification segment appears
    if (showVerifySegment && inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, [showVerifySegment]);

  const handleVerificationInputChange = (index, e) => {
    const value = e.target.value;

    // Handle backspace or delete key
    if (
      e.nativeEvent.inputType === 'deleteContentBackward' ||
      e.nativeEvent.inputType === 'deleteContentForward'
    ) {
      const newVerificationCode = [...verificationCode];
      newVerificationCode[index] = '';
      setVerificationCode(newVerificationCode);
      setVerificationError('');

      // Focus the previous input if the current one is empty
      // if (index > 0 && e.key === "Backspace") {
      //   inputRefs.current[index - 1].focus();
      // }

      // Focus the previous input if the current one is empty (for backspace)
      // if (index > 0 && e.nativeEvent.inputType === 'deleteContentBackward') {
      //   inputRefs.current[index - 1].focus();
      // }

      // Focus the next input if the current one is empty (for deleteContentForward)
      if (index < 5 && e.nativeEvent.inputType === 'deleteContentForward') {
        inputRefs.current[index + 1].focus();
      }
      return;
    }

    if (/^\d*$/.test(value) && value.length <= 1) {
      const newVerificationCode = [...verificationCode];
      newVerificationCode[index] = value;
      setVerificationCode(newVerificationCode);
      setVerificationError('');

      if (value && index < 5) {
        inputRefs.current[index + 1].focus();
      }
    } else {
      setVerificationError('');
    }
  };
  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace') {
      const value = e.target.value;

      if (value === '') {
        if (index > 0) {
          inputRefs.current[index - 1].focus();
        }
      } else {
        const newVerificationCode = [...verificationCode];
        newVerificationCode[index] = '';
        setVerificationCode(newVerificationCode);
      }
    }
  };

  const handleVerify = () => {
    const code = verificationCode.join('');
    const reqBody = {
      UserId: localStorage.getItem('userSessionId'),
      OTP: code,
    };
    fetch(`${apiURL}/web/login_verify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reqBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.IsValid) {
          navigate('/');
        } else {
          setVerificationError('OTP is invalid');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div
      className="loginPage"
      style={{ margin: '5%', height: '55vh', overflow: 'auto' }}
    >
      {' '}
      {!showVerifySegment ? (
        <h4 style={{ marginTop: '5%', marginLeft: '5%' }}>
          Sign in to get started
        </h4>
      ) : (
        <h4 style={{ marginTop: '5%', marginLeft: '5%' }}>OTP Verification</h4>
      )}
      {!showVerifySegment ? (
        <form onSubmit={formik.handleSubmit}>
          <CustomTextField
            className={
              formik.touched.email && Boolean(formik.errors.email)
                ? 'mb-5'
                : 'mb-4'
            }
            label="Work email address"
            variant="outlined"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            fullWidth
          />
          <CustomTextField
            className={
              formik.touched.password && Boolean(formik.errors.password)
                ? 'mb-5'
                : 'mb-4'
            }
            label="Password"
            variant="outlined"
            fullWidth
            type={showPassword ? 'text' : 'password'}
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CustomIconButton onClick={handleTogglePassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </CustomIconButton>
                </InputAdornment>
              ),
            }}
          />
          <button
            className="forgotPwd"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setSelectedRegComponent('fpwd');
            }}
          >
            <strong>Forgot password?</strong>
          </button>
          <button className="continueBtn" type="submit">
            Continue
          </button>
        </form>
      ) : (
        <div className="verificationSegment" style={{ marginTop: '49px' }}>
          <span style={{ marginLeft: '20px', color: 'lightblue' }}>
            Enter the 6-digit OTP
          </span>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              gap: '10px',
              marginTop: '20px',
            }}
          >
            {verificationCode.map((digit, index) => (
              <input
                key={index}
                type="text"
                value={digit}
                onChange={(e) => handleVerificationInputChange(index, e)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                ref={(input) => (inputRefs.current[index] = input)}
                style={{
                  width: '45px',
                  height: '45px',
                  textAlign: 'center',
                  fontSize: '20px',
                  background: 'transparent',
                  color: 'white',
                  outlineColor: 'blue',
                  borderRadius: '8px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  lineHeight: '1',
                  border: '2px solid lightblue',
                  outline: 'none',
                }}
              />
            ))}
          </div>
          <button
            className="continueBtn"
            type="button"
            onClick={handleVerify}
            style={{ marginTop: '100px' }}
          >
            Verify
          </button>

          {verificationError && (
            <p style={{ color: 'red', marginLeft: '5%' }}>
              {verificationError}
            </p>
          )}
        </div>
      )}
      {errorMsg && <p style={{ color: 'red', marginLeft: '5%' }}>{msg}</p>}
    </div>
  );
}
