import React, { useEffect, useState } from 'react';
import TranslateCreate from './TranslateCreate';
import TranslateResult from './TranslateResult';
import './spectralTranslate.css';
import TranslateHome from './TranslateHome';
import { Routes, Route, Link, Outlet, useLocation } from 'react-router-dom';

export default function SprectralTranslate({
  setSearchText,
  searchText,
  setUserInfo,
  selectedTranslateComponent,
  setSelectedTranslateComponent,
  menuLock,
  setMenuLock,
  projectName,
  setProjectName,
}) {
  const [projectData, setProjectData] = useState({
    ProjectId: '',
    ProjectName: '',
    VideoUrl: '',
  });
  const [projectId, setProjectId] = useState('');
  const projectIdLc = projectId.toLowerCase();
  const [dubbedData, setDubbedData] = useState([]);
  const [processingData, setProcessingData] = useState([]);
  const [render, setRender] = useState(true);
  const apiURL = process.env.REACT_APP_API_URL;
  const [pName, setPName] = useState('');

  const location = useLocation();

  useEffect(() => {
    document.title = 'Translate - Spectral Studio';
    setSearchText('');
  }, []);

  useEffect(() => {
    if (selectedTranslateComponent !== 'tHome') {
      setMenuLock(true);
    }
  }, [selectedTranslateComponent]);

  useEffect(() => {
    const isTranslateHome = location.pathname === '/translate';
    if (isTranslateHome) {
      setMenuLock(false);
    } else {
      setMenuLock(true);
    }
  }, [location.pathname]);

  function fetchUserInfo() {
    const reqBody = {
      UserId: localStorage.getItem('userSessionId'),
    };
    fetch(`${apiURL}/web/get_user_info`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers if needed
      },
      body: JSON.stringify(reqBody),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the data from the API
        if (data.IsValid) {
          localStorage.setItem('userInfo', JSON.stringify(data.UserInfo));
        }
      })
      .catch((error) => {
        // Handle errors
        console.error('Error:', error);
      });
  }
  useEffect(() => {
    fetchUserInfo();
  }, []);

  const renderSelectedTranslateComponent = () => {
    switch (selectedTranslateComponent) {
      case 'tHome':
        return (
          <TranslateHome
            searchText={searchText}
            setSelectedTranslateComponent={setSelectedTranslateComponent}
            projectId={projectId}
            setProjectId={setProjectId}
            projectData={projectData}
            setProjectData={setProjectData}
            setDubbedData={setDubbedData}
            menuLock={menuLock}
            setMenuLock={setMenuLock}
            setPName={setPName}
            processingData={processingData}
            setProcessingData={setProcessingData}
            projectName={projectName}
            setProjectName={setProjectName}
          />
        );
      case 'translate1':
        return (
          <TranslateCreate
            setSelectedTranslateComponent={setSelectedTranslateComponent}
            projectId={projectId}
            setProjectId={setProjectId}
            projectData={projectData}
            setProjectData={setProjectData}
            pName={pName}
            setPName={setPName}
            projectName={projectName}
            setProjectName={setProjectName}
            setUserInfo={setUserInfo}
          />
        );
      case 'translate2':
        return (
          <TranslateResult
            selectedTranslateComponent={selectedTranslateComponent}
            setSelectedTranslateComponent={setSelectedTranslateComponent}
            projectId={projectId}
            setProjectId={setProjectId}
            dubbedData={dubbedData}
            setDubbedData={setDubbedData}
            key={render}
            render={render}
            setRender={setRender}
            pName={pName}
            setPName={setPName}
            processingData={processingData}
            setProcessingData={setProcessingData}
            projectName={projectName}
            setProjectName={setProjectName}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div className="spectralTranslate">
      {(location.pathname === '/translate' ||
        location.pathname === '/translate/home') && (
        <h2 className="mt-4 ms-5">Spectral Translate</h2>
      )}
      {(location.pathname === '/translate' ||
        location.pathname === '/translate/home') && (
        <span className="mt-2 ms-5" style={{ color: 'grey' }}>
          Fully autonomous, one-click localization.
        </span>
      )}
      {/* {renderSelectedTranslateComponent()} */}
      <Routes>
        <Route
          path="/"
          element={
            <TranslateHome
              searchText={searchText}
              setSelectedTranslateComponent={setSelectedTranslateComponent}
              projectId={projectId}
              setProjectId={setProjectId}
              projectData={projectData}
              setProjectData={setProjectData}
              setDubbedData={setDubbedData}
              menuLock={menuLock}
              setMenuLock={setMenuLock}
              setPName={setPName}
              processingData={processingData}
              setProcessingData={setProcessingData}
              projectName={projectName}
              setProjectName={setProjectName}
            />
          }
        />
        <Route
          path="home"
          element={
            <TranslateHome
              searchText={searchText}
              setSelectedTranslateComponent={setSelectedTranslateComponent}
              projectId={projectId}
              setProjectId={setProjectId}
              projectData={projectData}
              setProjectData={setProjectData}
              setDubbedData={setDubbedData}
              menuLock={menuLock}
              setMenuLock={setMenuLock}
              setPName={setPName}
              processingData={processingData}
              setProcessingData={setProcessingData}
              projectName={projectName}
              setProjectName={setProjectName}
            />
          }
        />
        <Route
          path="create"
          element={
            <TranslateCreate
              setSelectedTranslateComponent={setSelectedTranslateComponent}
              projectId={projectId}
              setProjectId={setProjectId}
              projectData={projectData}
              setProjectData={setProjectData}
              pName={pName}
              setPName={setPName}
              projectName={projectName}
              setProjectName={setProjectName}
              setUserInfo={setUserInfo}
            />
          }
        />
        <Route
          path=":projectIdLc/result"
          element={
            <TranslateResult
              selectedTranslateComponent={selectedTranslateComponent}
              setSelectedTranslateComponent={setSelectedTranslateComponent}
              projectId={projectId}
              setProjectId={setProjectId}
              dubbedData={dubbedData}
              setDubbedData={setDubbedData}
              key={render}
              render={render}
              setRender={setRender}
              pName={pName}
              setPName={setPName}
              processingData={processingData}
              setProcessingData={setProcessingData}
              projectName={projectName}
              setProjectName={setProjectName}
            />
          }
        />
      </Routes>
    </div>
  );
}
