import React, { useState, useEffect } from 'react';
import Menu from '../Menu';
import UserPage from '../UserPage';
import { useLocation } from 'react-router-dom';
import './dashboard.css';

export default function Dashboard({
  selectedDashboardComponent,
  setSelectedDashboardComponent,
  userId,
  setUserId,
}) {
  // remove this from here later
  const [selectedTranscendComponent, setSelectedTranscendComponent] =
    useState('transcendHome');
  const [selectedVoiceTransferComponent, setSelectedVoiceTransferComponent] =
    useState('voiceTransferHome');

  const [selectedTranslateComponent, setSelectedTranslateComponent] =
    useState('tHome');
  const [selectedAdminComponent, setSelectedAdminComponent] =
    useState('manage-users');
  const [selectedDigitalTwinComponent, setSelectedDigitalTwinComponent] =
    useState('voice');
  const [selectedProjectsComponent, setSelectedProjectsComponent] =
    useState('project-list');
  const [menuLock, setMenuLock] = useState(false);

  const [showProjectsSubButtons, setShowProjectsSubButtons] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (selectedDashboardComponent !== 'projects') {
      setShowProjectsSubButtons(false);
    }
  }, [selectedDashboardComponent]);

  useEffect(() => {
    if (
      selectedTranscendComponent === 'transcendHome' &&
      selectedTranslateComponent === 'tHome' &&
      selectedVoiceTransferComponent == 'voiceTransferHome'
    ) {
      setMenuLock(false);
    } else {
      setMenuLock(true);
    }
  }, [
    selectedTranscendComponent,
    selectedTranslateComponent,
    selectedVoiceTransferComponent,
  ]);

  // useEffect(() => {
  //   if (
  //     location.pathname === '/transcend' ||
  //     location.pathname === '/transcend/' ||
  //     location.pathname === '/transcend/home' ||
  //     location.pathname === '/voice-transfer' ||
  //     location.pathname === '/voice-transfer/' ||
  //     location.pathname === '/voice-transfer/home' ||
  //     location.pathname === '/translate' ||
  //     location.pathname === '/translate/' ||
  //     location.pathname === '/translate/home'||
  //     location.pathname === '/'
  //   ) {
  //     setMenuLock(false);
  //   } else {
  //     setMenuLock(true);
  //   }
  // }, [location.pathname]);

  useEffect(() => {
    if (
      location.pathname === '/transcend/create' ||
      location.pathname === '/voice-transfer/create' ||
      location.pathname === '/translate/create'
    ) {
      setMenuLock(true);
    }
  }, [location.pathname]);

  const [render, setRender] = useState(true);

  return (
    <div>
      <div className={`dashboard ${menuLock ? 'dashboard-locked' : ''}`}>
        {menuLock === false && (
          <div className="menu-container">
            <Menu
              selectedDashboardComponent={selectedDashboardComponent}
              setSelectedDashboardComponent={setSelectedDashboardComponent}
              setSelectedTranslateComponent={setSelectedTranslateComponent}
              render={render}
              setRender={setRender}
              selectedTranscendComponent={selectedTranscendComponent}
              setSelectedTranscendComponent={setSelectedTranscendComponent}
              selectedVoiceTransferComponent={selectedVoiceTransferComponent}
              setSelectedVoiceTransferComponent={
                setSelectedVoiceTransferComponent
              }
              selectedAdminComponent={selectedAdminComponent}
              setSelectedAdminComponent={setSelectedAdminComponent}
              selectedDigitalTwinComponent={selectedDigitalTwinComponent}
              setSelectedDigitalTwinComponent={setSelectedDigitalTwinComponent}
              selectedProjectsComponent={selectedProjectsComponent}
              setSelectedProjectsComponent={setSelectedProjectsComponent}
              showProjectsSubButtons={showProjectsSubButtons}
              setShowProjectsSubButtons={setShowProjectsSubButtons}
            />
          </div>
        )}
        <div className="user-content">
          <UserPage
            selectedDashboardComponent={selectedDashboardComponent}
            setSelectedDashboardComponent={setSelectedDashboardComponent}
            selectedTranslateComponent={selectedTranslateComponent}
            setSelectedTranslateComponent={setSelectedTranslateComponent}
            menuLock={menuLock}
            setMenuLock={setMenuLock}
            render={render}
            setRender={setRender}
            userId={userId}
            setUserId={setUserId}
            selectedTranscendComponent={selectedTranscendComponent}
            setSelectedTranscendComponent={setSelectedTranscendComponent}
            selectedVoiceTransferComponent={selectedVoiceTransferComponent}
            setSelectedVoiceTransferComponent={
              setSelectedVoiceTransferComponent
            }
            selectedAdminComponent={selectedAdminComponent}
            setSelectedAdminComponent={setSelectedAdminComponent}
            selectedDigitalTwinComponent={selectedDigitalTwinComponent}
            setSelectedDigitalTwinComponent={setSelectedDigitalTwinComponent}
            selectedProjectsComponent={selectedProjectsComponent}
            setSelectedProjectsComponent={setSelectedProjectsComponent}
            showProjectsSubButtons={showProjectsSubButtons}
            setShowProjectsSubButtons={setShowProjectsSubButtons}
          />
        </div>
      </div>
    </div>
  );
}
